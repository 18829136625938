import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Meta from '../components/meta';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <Meta title="Page two" />
      <MDXTag name="h1" components={components}>{`Hi from the second page`}</MDXTag>
      <MDXTag name="p" components={components}>{`Welcome to page 2`}</MDXTag>
      <Link to="/">Go back to the homepage</Link>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    